export const locale = {
  lang: "vi",
  data: {
    NAV: {
      DASHBOARDS: "Tổng quan",
      AUTHEN: "Hệ thống",
      MASTER_DATA:"Danh mục chung",
      AUTHEN_LIST: {
        GROUP_USER: "Nhóm quyền người dùng",
        USER: "Người dùng hệ thống",
        APPPLAFORM :"Danh sách ứng dụng nền tảng",
      },
      MASTER_DATA_LIST: {
        ETHNICITY: "Các dân tộc Việt Nam",
        REGILIGIOUS: "Các tôn giáo Việt Nam",
        UNIVERSITY: "Các trường đại học, Cao đẳng",
        LEVEL: "Trình độ được đào tạo",
        ACADEMICTITLE: "Học hàm, học vị",
        WIEDEFIELD: "Lĩnh vực rộng được đào tạo",
        NAROWFIELD: "Lĩnh vực hẹp được đào tạo",
        ARCHIVEFOLDER: "Thư mực lưu trữ",
        LEVEL4INTERMEDIATE: "Giáo dục, đạo tạo cấp IV trình độ trung cấp, cao đẳng",
        LEVEL4COLLEGE: "Giáo dục, đạo tạo cấp IV trình độ cao đẳng, đại học",
        LEVEL4MASTER: "Giáo dục, đạo tạo cấp IV trình độ thạc sĩ, tiến sĩ"
      },
    }
  }
};
