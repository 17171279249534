// export enum Consts {
//   API_URL_LOCAL = "https://localhost:44331/api",
//   API_URL_PRODUCTION = "https://i-nxbapi.cnttvietnam.com.vn/api",
//   BACKEND_PROTOCOL = "http://",
//   // BACKEND_URL_LOCAL = "localhost:4200",
//   BACKEND_URL_LOCAL = "https://localhost:44368/api",
//   BACKEND_URL_PRODUCTION = "https://i-nxbapi.cnttvietnam.com.vn/"
// }
export enum Consts {
  API_URL_LOCAL = "https://localhost:44331/api",
  API_URL_PRODUCTION = "https://lgsp-api.digipro.com.vn/api",
  IP_PORT_SERVER="https://apivanhanhtruc.btsoftvn.com",
  BACKEND_PROTOCOL = "http://",
  // BACKEND_URL_LOCAL = "localhost:4200",
  BACKEND_URL_LOCAL = "https://localhost:44368/api",
  BACKEND_URL_PRODUCTION = "https://lgsp-api.digipro.com.vn/"
}

