import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";

import { ModalService } from "src/app/_services/modal.service";
import {
  FilterService,
  VirtualScrollService,
  GridComponent,
  DataStateChangeEventArgs,
} from "@syncfusion/ej2-angular-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { L10n } from '@syncfusion/ej2-base';
import * as moment from "moment";
import { Globals } from "src/app/common/globals";
import { CoreService } from "src/app/_services/core.service";
import { FieldSettingsModel } from "@syncfusion/ej2-angular-dropdowns";
import { Configs } from "src/app/common/configs";
import { Observable, Subject } from "rxjs";
import { TreeViewComponent } from "@syncfusion/ej2-angular-navigations";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigService } from "src/app/_services/config.service";
import { locale as english } from '../i18n/en';
import { locale as vietnam } from '../i18n/vi';
import { TranslationLoaderService } from "src/app/common/translation-loader.service";
import { Subscription } from 'rxjs';
import { Notification } from "src/app/common/notification";
import * as _ from 'lodash';
@Component({
  selector: "add-member-modals",
  styleUrls: ["./add-member-modals.component.scss"],
  templateUrl: "./add-member-modals.component.html",
  providers: [FilterService, VirtualScrollService],
  encapsulation: ViewEncapsulation.None,
})
export class AddMemberModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input("org_id") org_id: string;
  @Input("userIds") userIds: Array<any>;

  public searchOrgText = "";
  public search = {
    full_name: "",
    org_id: null
  };
  public modelAdd2: any;
  // form
  memberForm: FormGroup;
  languages: any;
  selectedLanguage: any;
  public lstOrgs = [];
  public lstOrgParent = [];
  public idTree;
  dataUsers = [];
  dataChoose = [];
  public modelAdd: any;
  getData: Subscription;
  // List data
  public dataMember: Observable<DataStateChangeEventArgs>;
  public state: DataStateChangeEventArgs;
  public query = new Query();
  @ViewChild("treeview", { static: false })
  public treeview: TreeViewComponent;

  // view child
  @ViewChild("overviewgridMember", { static: false })
  public gridInstance: GridComponent;

  @ViewChild("overviewgridMemberChoose", { static: false })
  public gridChoose: GridComponent;

  // fields
  public fieldsOtherlist: FieldSettingsModel = { text: 'name', value: 'id' };
  public fields: FieldSettingsModel = { text: 'name', value: 'id' };
  public fieldTreeView: Object = {};

  private element: any;
  flagFirstLoad = false;
  // Private
  private _unsubscribeAll: Subject<any>;
  pageIndex: number = 0;
  id_temp: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private globals: Globals,
    public configs: Configs,
    private _coreService: CoreService,
    private _configService: ConfigService,
    private _tlaTranslationLoaderService: TranslationLoaderService,
    private _formBuilder: FormBuilder,
    private notification: Notification,
  ) {
    this.dataMember = _coreService;
    this.element = el.nativeElement;
    this.getOrg();
    // Set language
    this.languages = this.globals.languages;

    this._configService._configSubject.next('true');
    // Load file language
    this._tlaTranslationLoaderService.loadTranslations(vietnam, english);
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    L10n.load(this.configs.languageGrid);
  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }

    document.body.appendChild(this.element);

    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // sinh số tt
  formatStt = (index: string) => {
    if (this.gridInstance) {
      return (
        (this.gridInstance.pageSettings.currentPage - 1) *
        this.gridInstance.pageSettings.pageSize +
        parseInt(index, 0) +
        1
      );
    }
  };

  // click lấy ds nhân viên theo phòng ban
  nodeClicked = (e) => {
    setTimeout(() => {
      // tim vi tri id phong ban trung voi org_id click tree
      let indexOrg = _.findIndex(this.lstOrgs, item => {
        return item.id === e.nodeData.id;
      });
      if (indexOrg > -1) {
        this.search.org_id = this.lstOrgs[indexOrg].id;
        this.flagFirstLoad = false;
        this.getListData();
      }
    }, 200)

  };

  //lấy dữ liệu sơ đồ tổ chức
  getOrg() {
    this._coreService
      .Post("/organization/getTree", {
        parent_id: null
      })
      .subscribe((res: any) => {
        if (res.data && res.data.length > 0) {
          for (let n = 0; n < res.data.length; n++) {
            this.prepareDataRender(res.data[n]);
          }
        }
        this.lstOrgs = _.sortBy(this.lstOrgs, "name");
        this.fieldTreeView = {
          dataSource: this.lstOrgs,
          id: "id",
          text: "name",
          parentID: "parent_id",
          hasChildren: "hasChild",
          expanded: "expanded"
        };
      });
  }

  prepareDataRender = (object: any, parent_id?: any) => {
    if (parent_id) {
      object.parent_id = parent_id;
    }
    if (object.childs && object.childs.length > 0) {
      object.expanded = true;
      object.hasChild = true;
      for (let i = 0; i < object.childs.length; i++) {
        this.prepareDataRender(object.childs[i], object.id);
      }
    }
    this.lstOrgs.push(object);
  };

  // open modal
  open(): void {
    setTimeout(() => {
      if (this.userIds && this.userIds.length > 0) {
        this.dataChoose = this.userIds;
      }
      this.flagFirstLoad = true;
      this.getListData();
      this.element.style.display = "block";
      document.body.classList.add("app-modal-open");
    }, 100);
  }

  // close modal
  close(): void {
    this.treeview.selectedNodes = [];
    this.treeview.refresh();
    this.element.style.display = "none";
    document.body.classList.remove("app-modal-open");
    this.modalService.modalStatus.next("close");

    this.search = {
      org_id: null,
      full_name: null,
    };

    this.getListData();
  }

  getListData = () => {
    this._coreService.Post("/user/list", {
      page_no: 1,
      page_size: 10000,
      full_name: this.search.full_name ? this.search.full_name : "",
      org_id: this.search.org_id ? this.search.org_id : null
    }).subscribe((res) => {
      if (res && res.code == "200") {
        this.dataUsers = [];
        if (res.data && res.data.data) {
          this.dataUsers = res.data.data;

          let indexs = [];
          let dataChoose = _.cloneDeep(this.dataChoose);
          if (dataChoose && dataChoose.length > 0) {
            let ids = _.map(dataChoose, "id");
            for (let i = 0; i < ids.length; i++) {
              let index = _.findIndex(this.dataUsers, (item) => {
                return item.id == ids[i];
              });

              if (index > -1) {
                indexs.push(index);
              }
            }

            setTimeout(() => {
              this.gridInstance.selectRows(indexs);
            }, 100);

          }
          this.flagFirstLoad = false;
        }
      }
    })
    // const state = { skip: 0, take: 20000 };
    // if (this.state && this.state.take) {
    //   this.state.skip = this.state.skip;
    //   this.state.take = this.state.take;
    //   this.pageIndex = Math.floor(this.state.skip / this.state.take);
    // } else {
    //   this.state = state;
    //   this.pageIndex = 0;
    // }
    // let extraParams = [];
    // if (this.search.full_name) {
    //   extraParams.push({
    //     field: 'full_name',
    //     value: this.search.full_name
    //   });
    // }

    // if (this.search.org_id) {
    //   extraParams.push({
    //     field: 'org_id',
    //     value: this.search.org_id
    //   });
    // }

    // if (this.gridInstance) {
    //   this.gridInstance.goToPage(1);
    // }

    // this._coreService.execute(this.state, "/user/list", extraParams);

  };

  searchListEnter(e) {
    setTimeout(() => {
      if (e.keyCode === 13) {
        this.flagFirstLoad = false;
        this.getListData();
      }
    }, 200);
  }

  chooseMember = () => {
    this.modalService.modalStatus.next({
      type: "addMemberGrid",
      objMemberGrid: this.dataChoose
    });
    this.close();
  }

  // filter chung
  public onFilteringOtherList(e, lst) {
    e.preventDefaultAction = true;
    const predicate = new Predicate("name", "contains", e.text, true, true);
    this.query = new Query();
    this.query = e.text !== "" ? this.query.where(predicate) : this.query;
    e.updateData(lst, this.query);
  }

  selectMember = (event) => {
    setTimeout(() => {
      if (!this.flagFirstLoad && ((event.name === "rowSelecting" && event.isCtrlPressed) || (event.name === "rowDeselecting" && event.isInteracted))) {
        if (event.name === "rowSelecting" && event.isCtrlPressed) {
          const rowSelects: any = this.gridInstance.getSelectedRecords();
          if (rowSelects && rowSelects.length > 0) {
            for (let i = 0; i < rowSelects.length; i++) {
              let index = _.findIndex(this.dataChoose, (item) => {
                return item.id == rowSelects[i].id;
              });

              if (index == -1) {
                this.dataChoose.push(rowSelects[i]);
              }
            }
          }
        } else if (event.name === "rowDeselecting" && event.isInteracted) {
          if (event.data && event.data.length > 0) {
            for (let i = 0; i < event.data.length; i++) {
              let index = _.findIndex(this.dataChoose, (item) => {
                return item.id == event.data[i].id;
              });

              if (index > -1) {
                this.dataChoose.splice(index, 1);
              }
            }
          }
        }
      }
    }, 50);
  };
}
