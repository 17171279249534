import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { DatePickerAllModule, DateTimePickerAllModule } from "@syncfusion/ej2-angular-calendars";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import { CheckBoxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxModule, RadioButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxAllModule, MaskedTextBoxAllModule, ColorPickerAllModule, TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import {
  DropDownListAllModule,
  DropDownListModule,
  ListBoxAllModule,
  AutoCompleteAllModule,
  MultiSelectModule
} from "@syncfusion/ej2-angular-dropdowns";
import { ModalComponent } from "./modals.component";
import { ConfirmModalComponent } from './confirm-modals.component';
import { AddMemberModalComponent } from "./add-member-modals/add-member-modals.component";
import { TooltipAllModule } from "@syncfusion/ej2-angular-popups";
import { AddDelegateModalComponent } from "./add-delegate-modals/add-delegate-modals.component";

import { ShowModalComponent } from "./show-modals.component";
import { ConfirmModalResetComponent } from "./confirm-modals-reset.component";
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListAllModule,
    FormsModule,
    GridAllModule,
    DatePickerAllModule,
    TreeViewModule,
    CheckBoxModule,
    TextBoxAllModule,
    NumericTextBoxAllModule,
    DropDownListModule,
    ListBoxAllModule,
    AutoCompleteAllModule,
    MultiSelectModule,
    DateTimePickerAllModule,
    TooltipAllModule,
    RadioButtonAllModule],


  declarations: [ModalComponent, ConfirmModalComponent, AddMemberModalComponent, AddDelegateModalComponent, ShowModalComponent, ConfirmModalResetComponent],
  exports: [ModalComponent, ConfirmModalComponent, AddMemberModalComponent, AddDelegateModalComponent, ShowModalComponent, ConfirmModalResetComponent]

})
export class ModalModule { }
