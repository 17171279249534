export enum ToolbarItem {
    'MENU'= 'menu',
    'ADD' = 'add',
    'EDIT'= 'edit',
    'DELETE' = 'delete',
    'APPROVE' = 'approve',
    'SEND_APPROVE' = 'send_approve',
    'DENIED' = 'denied',
    'UNDO' = 'undo',
    'VIEW'= 'view',
    'CLOSE'= 'close',
    'PAY'= 'pay',
    'EXPORT_EXCEL'= 'export_excel',
    'EXPORT_PDF'= 'export_pdf',
    'IMPORT'= 'import',
    'LOCK'= 'lock',
    'UNLOCK'= 'unlock',
    'SHOW_INACTIVE'= 'show_inactive',
    'PRINT'= 'print',
    'SAVE'= 'save',
    'DRAFT'='draft',
    'POST'='post',
    'CANCEL'= 'cancel',
    'EXPORT_TEMPLATE'= 'export_template',
    'BACK'= 'back',
    'CHOOSE_COLUMN'= 'choose_column',
    'COPY'= 'copy',
    'PREVIEW'= 'preview',
    'ADD_REQUEST'= 'add_request',
    'VIEW_DIARY'= 'view_diary',
    'FINISH'= 'finish',
    'CONFIRM'= 'confirm',
    'ADD_ORDER'= 'add_order',
    'ADD_PAYMENT'= 'add_payment',
    'PAYMENT_ORDER'= 'payment_order',
    'CANCEL_ORDER'= 'cancel_order',
    'RECEIVE_PROMOTION'= 'receive_promotion',
    'SPECIAL1'= 'special1',
    'SPECIAL2'= 'special2',
    'SPECIAL3'= 'special3',
    'SPECIAL4'= 'special4',
    'SPECIAL5'= 'special5',
    'SPECIAL6'= 'special6',
    'SPECIAL7'= 'special7',
    'SPECIAL8'= 'special8',
    'SPECIAL9'= 'special9',
    'SPECIAL10'= 'special10',
    'TRANSFERTERM' = 'transferterm',
    'DELETE_ALL'= 'delete_all',
    'RESTORE' = 'restore',
    'CLEAR' = 'clear',
    'NOTIFICATION' = 'notification',
    'CONDITION' = 'condition',
    'ACCEPT' = 'accept',
    'REPLY' = 'reply',
    'REFUSE' = 'refuse',
    'TRASH' = 'trash',
    'CHANGEPASS' = 'changepass',
    'ADD_SMS' = 'add_sms',
    'ADD_EMAIL' = 'add_email',
}
