export class User {
    id?: string;
    org_id?: string;
    full_name?: string;
    birth_date?: string;
    url_avatar?:string;
    gender_id?: number;
    phone?: string;
    work_phone?: string;
    email?: string;
    address?: string;
    user_group_id?:string;
    is_admin?:string;
    username?: string;
    password?: string;
    confirm_password?: string;
    Tendonvicongtac?:string;
    avatar?: string;
    signature?: string;
    status?: Boolean;
    user_type?: number;
    org_manage_id?: string;
    list_groups: Array<any>;
    list_group2s: Array<string>;
    list_org_manages: Array<any>;
    list_org_manage2s: Array<string>;
};

